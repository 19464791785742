.profile-container {
    padding-top: 100px;
    margin: 0 auto;
    max-width: 1092px;
    @media only screen and (max-width: 425px) {
        padding: 0 16px ;
        margin-top: 16px;
    }
    @media only screen and (min-width: 426px) and (max-width:768px) {
        padding: 0 60px ;
        margin-top: 100px;
    }

    @media only screen and (min-width: 767px) and (max-width:1024px) {
        padding: 0 60px ;
        margin-top: 100px;
    }
    

    .profile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        .logout-img {
            margin-right: 6px;
        }
        .hd-left {
            display: flex;
            .arrow-left {
                cursor: pointer;
                margin-left: -30px;
                @media only screen and (max-width: 425px) {
                    margin: 0;
                }
            }
        }

        .my-profile {
            color: $fc-black;
            font-weight: $fw-extra-bold;
            margin-left: 16px;
            font-size: $fs-24;
            @media only screen and (max-width: 425px) {
                font-size: 20px;
            }
        }
    }

    .profile-details {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 425px) {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
           
        }

        .profile-pic-container {
            display: inline-block;
            text-align: center;
            margin-right: 40px;
            position: relative;
            @media only screen and (max-width: 425px) {
                margin-right: 16px;
            }
            .profile-pic {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                border: 1px solid #999999;
                margin-bottom: 10px;
                object-fit: cover;
                @media only screen and (max-width: 425px) {
                    width: 100px;
                height: 100px;
                }

                & > input {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    border-radius: 50%;
                }

                img {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    filter: blur(3px) brightness(0.5);
                    -webkit-filter: blur(3px) brightness(0.5);
                    background: rgba(0, 0, 0, 0.5);
                }
                &:active {
                    filter: blur(3px) brightness(0.4);
                    -webkit-filter: blur(3px) brightness(0.4);
                    background: rgba(0, 0, 0, 0.6);
                }
                &:hover + .edit-cover-art {
                    opacity: 1;
                }
            }
            .edit-cover-art {
                position: absolute;
                top: 90px;
                left: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 0.2s ease;
                cursor: pointer;
                pointer-events: none;
                .modify-btn {
                    letter-spacing: 0.16px;
                    color: #ffffff;
                    margin-left: 8px;
                    font-size: 16px;
                    font-family: $roboto_font;
                    font-weight: $fw-medium;
                }
            }

            .my-photo {
                font-size: $fs-12;
                font-family: $roboto_font;
                font-weight: $fw-regular;
            }
        }

        .profile-details-container {
            @media only screen and (max-width: 425px) {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin-top: 16px;
               
            }
            .pro-name {
                font-size: $fs-24;
                font-weight: $fw-bold;
                color: $fc-black;
                @media only screen and (max-width: 425px) {
                    font-size: 20px;
                }
            }

            .pro-email {
                font-size: $fs-14;
                font-weight: $fw-regular;
                font-family: $roboto_font;
                color: $fc-black;
                margin: 8px 0 20px 0;
                @media only screen and (max-width: 425px) {
                    font-size: 12px;
                   
                }
            }

            .pro-password {
                font-size: $fs-16;
                font-weight: $fw-regular;
                font-family: $roboto_font;
                letter-spacing: 0.16px;
                cursor: pointer;
            }
        }
    }
}

// keeping outside because dialog rendered outside scope
.confirm-password-txt-input {
    margin-bottom: 30px;
}
