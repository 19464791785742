$shimmer-bg: #eaeaea;

@keyframes shimmerAnimation {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.shimmer-container {
    .gen-shimmer-card {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: shimmerAnimation;
        border-radius: 8px;
        margin-bottom: 20px;
        background: $shimmer-bg;
        background-image: linear-gradient(
            to right,
            $shimmer-bg 0%,
            #edeef1 20%,
            $shimmer-bg 40%,
            $shimmer-bg 100%
        );
        background-repeat: no-repeat;
    }
}

.create-podcast-shimmer {
    .podcast-media-shimmer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 38px;
        .img-desc-shimmer {
            margin-left: 24px;
        }
    }
}

.create-episode-shimmer {
    .season-ep-num-shimmer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
}

.episode-detail-view-shimmer {
    .ep-det-shimmer-hd {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 44px;
        grid-gap: 500px;
    }
    .shimmer-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
    }
    padding-top: 100px;
    margin: 0 auto;
    max-width: 1092px;
}

.gen-step-det-shimmer {
    .category-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
    }
}
