@import "../../components/productStyles/variable.scss";

body {
    margin: 0;
    font-family: $montserrat_font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    box-sizing: border-box;
    font-size: $fs-default;
}

code {
    font-family: $montserrat_font;
}

#root {
    min-height: 100vh;
}

.circularProgress {
    color: #fff;
    display: flex;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.5);
}

.flex-between {
    display: flex;
    justify-content: space-between;
}
