.admin-container {
    margin: 0 50px;
    padding-top: 100px;
    .chip-wrapper {
        display: flex;
        justify-content: space-between;
        .chips {
            display: flex;

            .content-type-chip {
                height: 32px;
                border-radius: 16px;
                background-color: #f2f0ed;
                padding: 4px 16px;
                display: flex;
                align-items: center;
                margin: 0 16px 16px 0;
                cursor: pointer;
            }
            .active-chip {
                background: #ffd100;
            }
        }
        .input {
            flex: 0.3;
        }
    }
}


// status dialog
.podcast-link {
    margin-top: 24px;
}