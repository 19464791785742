.blog-header-container {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    background-color: #ffffff;

    .header {
        height: 80px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        max-width: 904px;
        justify-content: space-between;
        @media only screen and (min-width: 1920px) {
            max-width: 1270px;
            height: 100px;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1919px) {
            max-width: 904px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
            max-width: 674px;
        }
        @media only screen and (min-width: 320px) and (max-width: 767px) {
            max-width: 90%;
            display: none;
        }
        .app-logo {
            cursor: pointer;
            @media only screen and (min-width: 1920px) {
                height: 50px;
            }
        }
        .header-search {
            position: relative;
            width: 30em;
            input {
                width: calc(100% - 20px);
                height: 40px;
                border-radius: 6px;
                background-color: #f7f7f7;
                border: none;
                padding-left: 20px;
            }
            img {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }
    }
}

.blog-active-header {
    box-shadow: 0px 3px 6px #00000029;
}

.blog-footer-container {
    background: #f7f7f7 0% 0% no-repeat padding-box;
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 1;
        margin: 0 auto;
        max-width: 904px;
        height: 200px;
        @media only screen and (min-width: 1920px) {
            max-width: 1270px;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1919px) {
            max-width: 904px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
            max-width: 674px;
        }
        @media only screen and (min-width: 320px) and (max-width: 767px) {
            height: 275px;
            flex-direction: column;
        }
        z-index: 99;
        .footer-app-logo {
            @media only screen and (min-width: 320px) and (max-width: 767px) {
                margin-top: 20px;
            }
        }
        .social-links {
            @media only screen and (min-width: 320px) and (max-width: 767px) {
                width: calc(100% - 32px);
                padding: 1rem;
            }
            .social-app-icons {
                @media only screen and (min-width: 320px) and (max-width: 767px) {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                }
            }
            .social-app-icons img {
                margin-right: 0.2rem;
                cursor: pointer;
            }
            .social-header {
                margin-bottom: 0.8rem;
                .follow-us {
                    text-align: left;
                    font-family: Roboto;
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #707070;
                    opacity: 1;
                }
            }
        }
    }
}
.mobile-header-container {
    position: fixed;
    z-index: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: #ffffff;
    display: none;
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        z-index: 999;
        display: inline-block;
    }
    .mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 904px;
        height: 80px;
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
            max-width: 674px;
        }
        @media only screen and (min-width: 320px) and (max-width: 767px) {
            max-width: 90%;
            z-index: 999;
        }
        .header-content {
            display: flex;
            justify-content: space-between;
            .mobile-app-logo {
                height: 30px;
                @media only screen and (min-width: 320px) and (max-width: 767px) {
                    height: 40px;
                }
            }
        }
        .header-search {
            position: relative;
            width: 100%;
            input {
                padding: 0px;
                width: calc(100% - 12px);
                height: 40px;
                border-radius: 6px;
                background: #f7f7f7 0% 0% no-repeat padding-box;
                border: 1px solid #212121;
                padding-left: 10px;
                outline: none;
            }
            img {
                position: absolute;
                top: 10px;
                right: 20px;
                cursor: pointer;
            }
        }
    }
}
.to-top {
    position: fixed;
    bottom: 16px;
    right: 32px;
    width: 40px;
    height: 40px;
    background: #212121 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 20px #0000001a;
    border-radius: 10px;
    opacity: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1f1f1f;
    text-decoration: none;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;
    z-index: 99;
}
.to-top.active {
    bottom: 32px;
    pointer-events: auto;
    opacity: 1;
}
