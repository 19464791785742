.home-container {


    .podcast-container {
        padding-top: 100px;
        margin: 0 auto;
        max-width: 1092px;
        .text-area-home {
            margin: 50px 0;
        }
        @media only screen and (min-width: 960px) and (max-width: 1279px) {
            max-width: 924px;
        }

        @media only screen and (min-width: 720px) and (max-width: 959px) {
            max-width: 660px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media only screen and (min-width: 640px) and (max-width: 719px) {
            max-width: 520px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media only screen and (max-width: 420px) {
           padding: 16px;
           margin-top: 60px;
        }
    }
}



.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;

    .header {
        height: 80px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        max-width: 1092px;
        justify-content: space-between;

        @media only screen and (min-width: 960px) and (max-width: 1279px) {
            max-width: 924px;
        }

        @media only screen and (min-width: 768px) and (max-width: 959px) {
            max-width: 660px;
        }

        @media only screen and (min-width: 640px) and (max-width: 767px) {
            max-width: 520px;
        }

        @media only screen and (max-width: 420px) {
            height: 60px;
            max-width: 90%;
        }

        img{
            @media only screen and (max-width: 420px) {
            
                height: 24px;
            }
        }

        .header-right-part {
            display: flex;
            align-items: flex-end;
        }

        .blog-name {
            font-family: roboto;
            font-size: 16px;
            color: #212121;
            margin-right: 30px;
            cursor: pointer;
        }

        .profile-pic {
            border: 1px solid #212121;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                
           
                
            }
            @media only screen and (max-width: 420px) {
                width: 24px;
                height: 24px;
            }

        }

        
    }
}
